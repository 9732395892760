<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon :disabled="loading" @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                    class="mt-5"
                    :label="$store.getters.translate('form')"
                    v-model="parent.name"
                    disabled/>
                <v-text-field
                    :label="$store.getters.translate('nl')"
                    v-model="dutch_name"/>
                <v-text-field
                    :label="$store.getters.translate('en')"
                    v-model="english_name"/>
                <v-select
                    :label="$store.getters.translate('type')"
                    v-model="record.type"
                    validate-on-blur
                    :items="$lodash.sortBy(question_types, 'text')"/>
                <v-combobox
                    v-if="record.type === 'select_single' || record.type === 'select_multiple'"
                    :label="$store.getters.translate('available_answers')"
                    chips
                    deletable-chips
                    small-chips
                    clearable
                    multiple
                    v-model="record.available_answers"/>
                <v-select
                    :label="$store.getters.translate('category')"
                    v-model="record.category_id"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    validate-on-blur
                    :items="translate(categories)"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  props: ["parent", "modal_name", "categories"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_form_question"),
        name: "formquestions",
        model: "formquestion",
      },
      record: {
        name: null,
        sort_order: 0,
        form_id: this.parent.id,
        category_id: null,
      },
      english_name: null,
      dutch_name: null,
      question_types: [],
      loading: false,
    };
  },
  created() {
    this.question_types = helpFunctions.question_types;
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        if(!this.english_name && !this.dutch_name) {
          this.$toasted.error(this.$store.getters.translate("please_fill_all_required_fields"));
        }
        else {
          this.record.form_id = this.parent.id;
          this.loading = true;
          let key = '';
          if (this.english_name) {
            key = this.$lodash.lowerCase(this.english_name);
          }
          else if (this.dutch_name) {
            key = this.$lodash.lowerCase(this.dutch_name);
          }
          this.record.name = key.replace(/ /g, "_");
          if(this.english_name) {
            let english_translation = {
              key: this.record.name,
              language: 'en',
              value: this.english_name,
              deletable: true,
            };
            this.createTranslation(english_translation);
          }
          if(this.dutch_name) {
            let dutch_translation = {
              key: this.record.name,
              language: 'nl',
              value: this.dutch_name,
              deletable: true,
            };
            this.createTranslation(dutch_translation);
          }
          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                this.$store.dispatch("refreshTranslations");
                this.$emit("refresh");
                this.reset();
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    createTranslation(translation) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations", translation)
          .then(() => {})
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    translate(categories) {
      let result = [];
      categories.forEach((category) => {
        result.push({
          id: category.id,
          name: this.$store.getters.translate(category.name),
        });
      });
      return this.$lodash.sortBy(result, "name");
    },
    reset() {
      this.record = {
        name: null,
        sort_order: 0,
        form_id: this.parent.id,
        category_id: null,
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>
