<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon :disabled="loading" @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                    class="mt-5"
                    :label="$store.getters.translate('form')"
                    v-model="parent.name"
                    disabled/>
                <v-text-field
                    :label="$store.getters.translate('nl')"
                    v-model="dutch_name"/>
                <v-text-field
                    :label="$store.getters.translate('en')"
                    v-model="english_name"/>
                <v-select
                    :label="$store.getters.translate('type')"
                    v-model="record.type"
                    validate-on-blur
                    :items="$lodash.sortBy(question_types, 'text')"/>
                <v-combobox
                    v-if="record.type === 'select_single' || record.type === 'select_multiple'"
                    :label="$store.getters.translate('available_answers')"
                    chips
                    deletable-chips
                    small-chips
                    clearable
                    multiple
                    v-model="record.available_answers"/>
                <template v-if="record.type === 'select_single' || record.type === 'select_multiple'">
                  <template v-for="(answer, index) in record.available_answers">
                    <base-row
                        v-if="files[answer]"
                        class="mt-3"
                        :key="index"
                        :label="answer"
                        :value="answer"
                        :link="false"
                        :hide_copy="true"
                        :deletable="true"
                        @delete="deleteImage(answer)"/>
                    <v-file-input
                        class="mt-3"
                        :key="index"
                        :label="answer"
                        :clearable="false"
                        v-model="files_to_upload[answer]"
                        @change="saveImage(answer)"/>
                  </template>
                </template>
                <v-select
                    :label="$store.getters.translate('category')"
                    v-model="record.category_id"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    validate-on-blur
                    :items="translate(categories)"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  props: ["parent", "modal_name", "categories"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("edit_form_question"),
        name: "formquestions",
        model: "formquestion",
      },
      record: {
        name: null,
        sort_order: 0,
        form_id: this.parent.id,
        category_id: null,
      },
      english_name: null,
      dutch_name: null,
      question_types: [],
      loading: false,
      files: {},
      files_to_upload: {}
    };
  },
  created() {
    this.question_types = helpFunctions.question_types;
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.files = {};
          this.files_to_upload = {};
          this.record = response.data;
          this.record.answers.forEach((answer) => {
            if(answer.image) {
              this.files[answer.name] = answer.image;
            }
          });
          this.loadTranslation(this.record.name);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        if(!this.english_name && !this.dutch_name) {
          this.$toasted.error(this.$store.getters.translate("please_fill_all_required_fields"));
        }
        else {
          this.record.form_id = this.parent.id;
          this.loading = true;
          if(this.english_name) {
            let english_translation = {
              key: this.record.name,
              language: 'en',
              value: this.english_name,
            };
            this.updateTranslation(english_translation);
          }
          if(this.dutch_name) {
            let dutch_translation = {
              key: this.record.name,
              language: 'nl',
              value: this.dutch_name,
            };
            this.updateTranslation(dutch_translation);
          }
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                this.$store.dispatch("refreshTranslations");
                this.$emit("refresh");
                this.reset();
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    loadTranslation(key) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations/get-by-key", { key: key })
          .then((response) => {
            response.data.forEach((translation) => {
              if (translation.language === 'en') {
                this.english_name = translation.value;
              }
              else if (translation.language === 'nl') {
                this.dutch_name = translation.value;
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateTranslation(translation) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations/update-by-key", translation)
          .then(() => {})
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    translate(categories) {
      let result = [];
      categories.forEach((category) => {
        result.push({
          id: category.id,
          name: this.$store.getters.translate(category.name),
        });
      });
      return this.$lodash.sortBy(result, "name");
    },
    saveImage(answer) {
      if(this.files_to_upload[answer] && this.files[answer]) {
        this.$toasted.error(this.$store.getters.translate("file_already_exists"));
        this.files_to_upload[answer] = null;
        delete this.files_to_upload[answer];
      }
      else if(this.files_to_upload[answer]) {
        this.loading = true;
        const formData = new FormData();
        formData.append("image", this.files_to_upload[answer]);
        formData.append("name", answer);
        formData.append("question_id", this.record.id);
        this.$http
            .post(this.$store.getters.appUrl + "v2/questionanswers/uploadfiles", formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then((response) => {
              this.loading = false;
              this.files[answer] = response.data;
              this.files_to_upload[answer] = null;
              delete this.files_to_upload[answer];
              this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    deleteImage(answer) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/questionanswers/deletefiles", { name: answer, question_id: this.record.id })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
            this.load(this.record.id);
          })
          .catch(function (error) {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    reset() {
      this.record = {
        name: null,
        sort_order: 0,
        form_id: this.parent.id,
        category_id: null,
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>
